// Initialize and add the map
// The location of Company
export default function initMap() {
    let companyGeoPosition = { lat: 53.575538, lng: 10.036682 };
    google.maps.Map.prototype.setCenterWithOffset= function(latlng, offsetX, offsetY) {
        var map = this;
        var ov = new google.maps.OverlayView();
        ov.onAdd = function() {
            var proj = this.getProjection();
            var aPoint = proj.fromLatLngToContainerPixel(latlng);
            aPoint.x = aPoint.x+offsetX;
            aPoint.y = aPoint.y+offsetY;
            map.setCenter(proj.fromContainerPixelToLatLng(aPoint));
        };
        ov.draw = function() {};
        ov.setMap(this);
    };

    var map = new google.maps.Map(
        document.getElementById('g-map'),
        {
            zoom: 14,
            center: companyGeoPosition,
            disableDefaultUI: true,
            styles: [
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels",
                    "stylers": [{ "visibility": "off" }]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [{ "visibility": "off" }]
                },
                {
                    "featureType": "poi.business",
                    "stylers": [{ "visibility": "off" }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text",
                    "stylers": [{ "visibility": "off" }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels",
                    "stylers": [{ "visibility": "off" }]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{ "color": "#def5ff" }]
                }
            ]
        }
    );

    var marker = new google.maps.Marker({position: companyGeoPosition, map: map});
    /*
    var offset = getOffsetVals();
    map.setCenterWithOffset(marker.getPosition(), offset.x, offset.y);
    */
}

// Below is a currently not used helper function to add an offset to maps viewport.
function getOffsetVals(){
    var width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    var obj = { x: 0, y: -200 };

    if (width > 768)
    {
      obj.x = 190;
      obj.y = 0;
    }
    return obj;
};
