require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")

import "lazysizes"
import "controllers"

// stylesheets
import "../source/css/application";

// small js snippets
import "../source/js/main";
