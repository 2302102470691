import "./snippets/ekko-lightbox.min";
import init_animateIt from "./snippets/css3-animate-it";
import initMap from "./googleMaps";
// page transitions with turbolinks
// Found here:
// https://www.cookieshq.co.uk/posts/page-animations-with-turbolinks
// Github Repo:
// https://github.com/cookieshq/rails-turbolink-animations
import setupAnimations from './transition-animations/setup_animations';
import ahoy from "ahoy.js";
ahoy.configure({ cookies: false });

window.initMap = initMap;

$(function() {
    // Accordion Helper
    $(".accordion-item").on("show.bs.collapse hide.bs.collapse", function(){
        $(this).parent().find(".toggler").toggleClass("rotate-toggler");
    });

    // Mobile Navigation
    $('input#burger').on("change", function(){
        if($(this).is(':checked')) {
            $('body').addClass("hideOverscroll");
        } else {
            $('body').removeClass("hideOverscroll");
        }
    });

    // Animation for mobile navigation
    $(".burger-nav-container a").on("click", function(){
        $('input#burger').prop("checked", false);
    });

    // add space to page header image if sub navigation is present
    if ( ($(window).width() >= 990) && $(".navigation .nav-child-list .nav-child").hasClass("current") ){
       $(".header-image-wrapper").addClass("header-nav-spacer");
    } else {
       $(".header-image-wrapper").removeClass("header-nav-spacer");
    };


});

function init_partners_effect(){
    $(".partners-container .partner").on("click", function(){
        $(this).toggleClass("clicked");
    });
}

function acceptCookies(){
    $(".cookie-consent-btn").on("click", function(event){
        var url = this.getAttribute("href");
        event.preventDefault();
        $.ajax({
            url: url,
            method: "GET"
        }).done( function(){
            $(".cookie-consent-container").addClass("dismiss");
            setTimeout(function(){
                $(".cookie-consent-container").remove();
            }, 1000);
        });
    });
}

function initModal(){
    $('#defaultModal').on('shown.bs.modal', function (event) {
        var modal = $(this);

        var button = $(event.relatedTarget);
        var title = button.data("title");
        var buttonText = button.data("buttontext");
        var buttonClass = button.data("buttonclass");
        var buttonLink = button.data("buttonlink");
        var size = button.data("size");
        var href = button.attr("href");

        if (size){
            if(size=="lg"){
                modal.find(".modal-dialog").addClass("modal-lg");
            } else if(size=="sm") {
                modal.find(".modal-dialog").addClass("modal-sm");
            }
        }

        if (href.length > 0){
            $.get(href, function(data){
                let modalBody = modal.find(".modal-body").html(data);
                // special for /datenschutz
                if (href == "/datenschutz"){
                    modalBody.find(".page-headline").removeClass("mt-5");
                    modalBody.find(".col-sm-12").removeClass("col-lg-8 pr-lg-5");
                }
            });
        }

        if (title){
            modal.find(".modal-title").text(title);
        }
        // Buttons
        if (buttonText){
            modal.find(".modal-footer .draw-btn").text(buttonText);
        }
        if (buttonClass){
            modal.find(".modal-footer .draw-btn").addClass(buttonClass);
        }
        if (buttonLink){
            modal.find(".modal-footer .draw-btn").attr("href", buttonLink);
        }
        // reinit everything for modal stuff
        turbolinks_init();
    })
}

function initLightbox(){
    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox({ wrapping: false });
    });
}

function initAhoy(){
    $('.expand_real_estate').on('click', function(event) {
        // TODO: stop console output
        // console.log("fire");
        let target = event.target;
        let trackdata = {
            real_estate_id: target.dataset.id,
            title: target.dataset.title,
        }

        ahoy.track("real estate expose viewed", trackdata);
    });
}

function turbolinks_init() {
    init_animateIt();
    init_partners_effect();
    acceptCookies();
    initModal();
    initLightbox();
    initAhoy();
    // how to init right?
    initMap();
}

$(document).on('turbolinks:load', function(){
    turbolinks_init();
});

setupAnimations();